:root {
  --color-bg: #fdf1f2;
  --color-heart: #ea442b;
  --easing: cubic-bezier(0.7, 0, 0.3, 1);
  --duration: 0.5s;
}

.like-button {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  z-index: 2;
  display: flex;
  justify-content: center !important;
    align-items: center !important;
  -webkit-transition: -webkit-transform var(--duration) var(--easing);
  transition: -webkit-transform var(--duration) var(--easing);
  transition: transform var(--duration) var(--easing);
  transition: transform var(--duration) var(--easing),
    -webkit-transform var(--duration) var(--easing);
  cursor: pointer;
}
.like-button:before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* box-shadow: 0 0.3em 0.6em rgba(0, 0, 0, 0.3);*/ /* Removed shadow */
  border-radius: inherit;
  -webkit-transition: inherit;
  transition: inherit;
}
.like-button:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  z-index: -1;
}
.like-button:active:before {
  -webkit-animation: depress-shadow var(--duration) var(--easing) both;
  animation: depress-shadow var(--duration) var(--easing) both;
}
.like-button.liked:after {
  -webkit-animation: depress var(--duration) var(--easing) both;
  animation: depress var(--duration) var(--easing) both;
}
@-webkit-keyframes depress {
  from,
  to {
    -webkit-transform: none;
    transform: none;
  }
  50% {
    -webkit-transform: translateY(5%) scale(0.9);
    transform: translateY(5%) scale(0.9);
  }
}
@keyframes depress {
  from,
  to {
    -webkit-transform: none;
    transform: none;
  }
  50% {
    -webkit-transform: translateY(5%) scale(0.9);
    transform: translateY(5%) scale(0.9);
  }
}
@-webkit-keyframes depress-shadow {
  from,
  to {
    -webkit-transform: none;
    transform: none;
  }
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@keyframes depress-shadow {
  from,
  to {
    -webkit-transform: none;
    transform: none;
  }
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
.like-wrapper {
  display: grid;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 1;
}
.like-wrapper > * {
  margin: auto;
  grid-area: 1 / 1;
}

.heart {
  width: 20px;
  height: 20px;
  display: block;
  -webkit-transform-origin: center 80%;
  transform-origin: center 80%;
}
.heart > path {
  fill: currentColor;
  -webkit-transition: fill var(--duration) var(--easing);
  transition: fill var(--duration) var(--easing);
}
.like-button.liked .heart > path {
  fill: var(--color-heart);
}
.like-button.liked .heart {
  -webkit-animation: heart-bounce var(--duration) var(--easing);
  animation: heart-bounce var(--duration) var(--easing);
}
@-webkit-keyframes heart-bounce {
  40% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  0%,
  80%,
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes heart-bounce {
  40% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  0%,
  80%,
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/* Added wrapper to prevent layout jank with resizing particles */
.particles {
  position: absolute;
  width: 1px;
  height: 1px;
}

.particle {
  position: absolute;
  top: 0;
  left: 0;
  height: 0.2em;
  width: 0.2em;
  border-radius: 0.1em;
  background-color: var(--color);
  --percentage: calc(var(--i) / var(--total-particles));
  --Θ: calc(var(--percentage) * 1turn);
  -webkit-transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(0)
    scaleY(0);
  transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(0) scaleY(0);
  -webkit-transition: all var(--duration) var(--easing);
  transition: all var(--duration) var(--easing);
}
.like-button.liked .particle {
  -webkit-animation: particles-out calc(var(--duration) * 1.2) var(--easing)
    forwards;
  animation: particles-out calc(var(--duration) * 1.2) var(--easing) forwards;
}
@-webkit-keyframes particles-out {
  50% {
    height: 0.4em;
  }
  50%,
  60% {
    height: 0.4em;
    -webkit-transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(0.8em)
      scale(1);
    transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(0.8em) scale(1);
  }
  60% {
    height: 0.2em;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(1em)
      scale(0);
    transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(1em) scale(0);
  }
}
@keyframes particles-out {
  50% {
    height: 0.4em;
  }
  50%,
  60% {
    height: 0.4em;
    -webkit-transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(0.8em)
      scale(1);
    transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(0.8em) scale(1);
  }
  60% {
    height: 0.2em;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(1em)
      scale(0);
    transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(1em) scale(0);
  }
}
.ripple {
  position: absolute;
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;
}
.ripple:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0.4em solid var(--color-heart);
  border-radius: inherit;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.like-button.liked .ripple:before {
  -webkit-animation: ripple-out var(--duration) var(--easing);
  animation: ripple-out var(--duration) var(--easing);
}
@-webkit-keyframes ripple-out {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  to {
    -webkit-transform: scale(5);
    transform: scale(5);
  }
}
@keyframes ripple-out {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  to {
    -webkit-transform: scale(5);
    transform: scale(5);
  }
}
body {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background-color: var(--color-bg);
}

/* Reset the animation when clicking again! */
.like-button:focus {
  pointer-events: none;
  cursor: normal;
}
